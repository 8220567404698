:root {
	--primary: #79b500;
}

.App {
	font-family: sans-serif;
	height: 100vh;
	/* width: 100vw; */
}

.darkText {
	color: #333333;
}

.brandText {
	color: #59b5d9;
}

/*.b-grid-header-container .b-sch-timeaxiscolumn{

}*/
.schedulerEventNoMargin.schedulerEventNoMargin.schedulerEventNoMargin {
	overflow: visible !important;
	background-image: none !important;
	/* background: transparent; */
}

.b-sch-style-plain .b-sch-event:not(.b-milestone),
.b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone) {
	/* border-color: transparent !important; */
	background-color: transparent !important;
}

.grid-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 64px 0px 1fr;
	gap: 0px 0px;
	grid-template-areas:
		"."
		"."
		".";
}

fieldset > .MuiFormControl-root {
	display: block;
}

fieldset {
	border: none;
}

.schedulerEventNoMargin.schedulerEventNoMargin > div.b-sch-event-content {
	margin: 0;
}

.plannedRequirement {
}

.UTEvent.UTEvent.UTEvent.UTEvent.UTEvent.UTEvent {
	background-color: #000 !important;
	opacity: 0.5;
	border-color: #000 !important;
	border-width: 1.5px;
}

.isLockedIcon::after {
	color: #fff;
	content: "lock";
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
}
.isLocked.isLocked.isLocked.isLocked.isLocked:after {
	pointer-events: none;
	border-radius: 10px;
	box-sizing: border-box;
	padding-top: 15px;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-color: #59b5d9 !important;
	opacity: 0.1;
	text-align: center;
	vertical-align: middle;

	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}

.startedAction::after {
	top: 0;
	left: 0;
	color: #fff;
	/* content: "check_circle_outline"; */
	content: "Finished";
	z-index: 5;
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	pointer-events: none;
	border-radius: 10px;
	box-sizing: border-box;
	padding-top: 15px;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
	background: #d67900;
	background: repeating-linear-gradient(
		-135deg,
		#aa6000,
		#d67900 20px,
		#fff 20px,
		#d67900 50px
	);
	opacity: 0.4;
	text-align: center;
	vertical-align: middle;

	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}

.finishedAction::after {
	top: 0;
	left: 0;
	color: #fff;
	/* content: "check_circle_outline"; */
	content: "Finished";
	z-index: 5;
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	pointer-events: none;
	border-radius: 10px;
	box-sizing: border-box;
	padding-top: 15px;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
	background: #67a777;
	background: repeating-linear-gradient(
		-135deg,
		#67a777,
		#67a777 20px,
		#ffffff 20px,
		#a2ffb9 50px
	);
	opacity: 0.4;
	text-align: center;
	vertical-align: middle;

	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}

@keyframes animate {
	from {
		background-position: 2000px 0;
	}

	to {
		background-position: 0 0;
	}
}

.status-indicator {
	position: absolute;
	width: 100%;
	height: 100%;
}
.completeAction {
	position: relative;
	overflow: hidden;
	border-radius: 6px;
	border: 1px dotted #67a777;
}
.completeAction .status-indicator::before {
	content: "";
	position: absolute;
	z-index: -5;
	opacity: 0.2;
	left: -16px;
	right: 0;
	top: 0;
	bottom: 0;
	width: auto;
	height: auto;
	background-size: 212px 212px;
	background-image: repeating-linear-gradient(
		-45deg,
		white 0%,
		white 7.5px,
		#67a777 7.5px,
		#67a777 15px,
		white 15px,
		white 22.5px,
		#67a777 22.5px,
		#67a777 30px
	);
	-webkit-animation: animate 60s infinite linear forwards;
	-moz-animation: animate 60s infinite linear forwards;
	animation: animate 60s infinite linear forwards;
}

.inProgressAction {
	position: relative;
	overflow: hidden;
	border-radius: 6px;
	border: 1px dashed #d67900;
}
.inProgressAction .status-indicator::before {
	content: "";
	position: absolute;
	z-index: -3;
	opacity: 0.2;
	left: -16px;
	right: 0;
	top: 0;
	bottom: 0;
	width: auto;
	height: auto;
	background-size: 212px 212px;
	background-image: repeating-linear-gradient(
		-45deg,
		white 0%,
		white 7.5px,
		#d67900 7.5px,
		#d67900 15px,
		white 15px,
		white 22.5px,
		#d67900 22.5px,
		#d67900 30px
	);
	-webkit-animation: animate 60s infinite linear forwards;
	-moz-animation: animate 60s infinite linear forwards;
	animation: animate 60s infinite linear forwards;
}

.b-sch-event-wrap.b-milestone-wrap
	.b-sch-event:not(.b-sch-event-withicon)
	.b-sch-event-content::before {
	border-color: transparent !important;
	background-color: transparent !important;
}


.locked {
	background-color: #000;
}

.nested {
	position: absolute;
	background-color: inherit;
	box-sizing: border-box;
	top: 0;
	bottom: 0;
	font-size: 0.7em;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 0.5em;
}

.nested.voyage {
	background-color: "yellow";
}

.b-sch-event {
	padding-left: 0;
	background-image: repeating-linear-gradient(
		45deg,
		#ddd,
		#ddd 10px,
		#eee 10px,
		#eee 20px
	);
	border-radius: 5px;
}

.b-sch-event-tooltip {
	background-color: #606060 !important;
	color: #fff !important;
	font-size: 12px !important;
	margin: 1px !important;
}

.b-sch-event.b-sch-event-selected,
.b-sch-event:hover {
	background-image: repeating-linear-gradient(
		45deg,
		#ccc,
		#ccc 10px,
		#ddd 10px,
		#ddd 20px
	) !important;
}

.b-sch-event-wrap:hover {
	background-color: #59b5d926;
}

.b-sch-event-content {
	background-color: inherit;
}

.b-resize-handle:before,
.b-resize-handle:before {
	z-index: 10;
}

.b-sch-event:not(.b-milestone).b-sch-event-selected.b-sch-event-selected
	.b-sch-event-content
	* {
	color: inherit;
}

.main-header-wrapper {
	min-height: 70px !important;
}
.main-header h2 {
	margin: auto;
}

.metrix-bar > div > span {
	margin: 0 10px;
}

.metrix-bar {
	display: flex;
	flex-wrap: wrap-reverse;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	align-content: space-around;
}

.filter-gray {
	filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(2104%)
		hue-rotate(316deg) brightness(98%) contrast(81%);
}

.fleet-req-text-input input,
.fleet-req-date-input input,
.fleet-req-dropdown button {
	border: none;
	font-size: 18px;
	font-weight: 400;
	padding: 0;
	text-align: start;
}

.fleet-req-dropdown button:hover,
.fleet-req-dropdown button:active {
	box-shadow: none !important;
}

.fleet-req-date-input input {
	width: 130px;
}

.fleet-req-date-input svg {
	width: 18px;
	height: 18px;
}

.hover-opacity:hover {
	opacity: 0.9;
	cursor: pointer;
}

.color.red {
	color: #ff0000;
}
.color.dark-red {
	color: #9c0000;
}
